<template>
    <b-card :title="`${$t('create')}`">
        <form @submit.prevent="storeProduct" @keydown="form.onKeydown($event)" class="create-form" enctype="multipart/form-data">
                <b-row>
                    <b-col sm="4">
                        <div class="form-group">
                            <label for="title">{{ $t('title') }}<span class="text-danger">*</span></label>
                            <input type="text" name="title" class="form-control" v-model="form.title" :class="{ 'is-invalid': form.errors.has('title') }">
                            <has-error :form="form" field="title" />
                        </div>
                        <div class="form-group">
                            <label for="price">{{ $t('group') }}<span class="text-danger">*</span></label>
                            <select class="form-control" v-model.number="form.group_id" :class="{ 'is-invalid': form.errors.has('group') }">
                                <optgroup v-if="group.children.length > 0" v-for="group in groups" :label="group.name" :key="group.id">
                                    <option v-for="child in group.children" :value="child.id" :key="child.id">{{ child.name }}</option>
                                </optgroup>
                                <option v-else v-for="group in groups" :value="group.id" :key="group.id">{{ group.name }}</option>
                            </select>
                            <has-error :form="form" field="group_id" />
                        </div>
                        <div class="form-group">
                            <label for="description">{{ $t('description') }}</label>
                            <textarea class="form-control" v-model="form.description" :class="{ 'is-invalid': form.errors.has('description') }"></textarea>
                            <has-error :form="form" field="description" />
                        </div>
                        <div class="form-group">
                            <label for="price">{{ $t('price') }}</label>
                            <input type="text" name="price" class="form-control" v-model.number="form.price" :class="{ 'is-invalid': form.errors.has('price') }">
                            <has-error :form="form" field="price" />
                        </div>
                        <div class="form-group">
                            <label>{{ $t('image') }}</label>
                            <input type="file" class="form-control-sm" id="customFile" :class="{ 'is-invalid': form.errors.has('image') }" @change="changeInputFile('form', $event)">
                            <has-error :form="form" field="image" />
                        </div>
                    </b-col>
                    <b-col sm="8">
                        <b-row>
                            <b-col sm="6">
                                <b-row>
                                    <b-col>
                                        <div class="form-group">
                                            <label for="weight">{{ $t('weight') }}<span class="text-danger">*</span></label>
                                            <input type="text" name="weight" class="form-control form-control-sm" v-model="form.info.weight" :class="{ 'is-invalid': form.errors.has('weight') }">
                                            <has-error :form="form" field="weight" />
                                        </div>
                                        <div class="form-group">
                                            <label for="volume">{{ $t('volume') }}<span class="text-danger">*</span></label>
                                            <input type="text" name="volume" class="form-control form-control-sm" v-model="form.info.volume" :class="{ 'is-invalid': form.errors.has('volume') }">
                                            <has-error :form="form" field="volume" />
                                        </div>
                                        <div class="form-group">
                                            <label for="unit_of_weight">{{ $t('unit_of_weight') }}</label>
                                            <select class="form-control form-control-sm" v-model="form.info.unit_of_weight">
                                                <option value="">--</option>
                                                <option value="гр">гр</option>
                                                <option value="кг">кг</option>
                                            </select>
                                            <has-error :form="form" field="unit_of_weight" />
                                        </div>
                                    </b-col>
                                    <b-col>
                                        <div class="form-group">
                                            <label for="length">{{ $t('length') }}<span class="text-danger">*</span></label>
                                            <input type="text" name="length" class="form-control form-control-sm" v-model="form.info.length" :class="{ 'is-invalid': form.errors.has('material') }">
                                            <has-error :form="form" field="length" />
                                        </div>
                                        <div class="form-group">
                                            <label for="width">{{ $t('width') }}<span class="text-danger">*</span></label>
                                            <input type="text" name="width" class="form-control form-control-sm" v-model="form.info.width" :class="{ 'is-invalid': form.errors.has('width') }">
                                            <has-error :form="form" field="width" />
                                        </div>
                                        <div class="form-group">
                                            <label for="height">{{ $t('height') }}<span class="text-danger">*</span></label>
                                            <input type="text" name="height" class="form-control form-control-sm" v-model="form.info.height" :class="{ 'is-invalid': form.errors.has('height') }">
                                            <has-error :form="form" field="height" />
                                        </div>
                                        <div class="form-group">
                                            <label for="unit">{{ $t('unit') }}</label>
                                            <select class="form-control form-control-sm" v-model="form.info.unit">
                                                <option value="">--</option>
                                                <option value="шт">шт</option>
                                                <option value="л">л</option>
                                                <option value="мл">мл</option>
                                                <option value="мп">мп</option>
                                            </select>
                                            <has-error :form="form" field="unit" />
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="6">
                                <div class="form-group">
                                    <label for="sku">{{ $t('sku') }}<span class="text-danger">*</span></label>
                                    <input type="text" name="sku" class="form-control" v-model="form.info.sku" :class="{ 'is-invalid': form.errors.has('sku') }">
                                    <has-error :form="form" field="sku" />
                                </div>
                                <div class="form-group">
                                    <label for="type_of_marriage">{{ $t('type_of_marriage') }}</label>
                                    <input type="text" class="form-control form-control-sm" :placeholder="$t('type_of_marriage')" v-model="form.info.type_of_marriage" :class="{ 'is-invalid': form.errors.has('type_of_marriage') }">
                                    <small>Вказувати через кому</small>
                                    <has-error :form="form" field="type_of_marriage" />
                                </div>
                                <div class="form-group">
                                    <label for="type_of_nomenclature">{{ $t('type_of_nomenclature') }}</label>
                                    <select class="form-control form-control-sm" v-model="form.info.type_of_nomenclature">
                                        <option value=""> -- </option>
                                        <option value="stock">Запас</option>
                                        <option value="service">Послуга</option>
                                    </select>
                                </div>
                                
                                <div class="form-group">
                                    <label for="total_quantity">{{ $t('total_quantity') }}<span class="text-danger">*</span></label>
                                    <input type="text" name="total_quantity" class="form-control form-control-sm" v-model="form.info.total_quantity" :class="{ 'is-invalid': form.errors.has('total_quantity') }">
                                    <has-error :form="form" field="total_quantity" />
                                </div>
                                <div class="form-group">
                                    <label for="production_quntity">{{ $t('production_quntity') }}<span class="text-danger">*</span></label>
                                    <input type="text" name="production_quntity" class="form-control form-control-sm" v-model="form.info.production_quantity" :class="{ 'is-invalid': form.errors.has('production_quntity') }">
                                    <has-error :form="form" field="production_quntity" />
                                </div>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="12">
                        <hr>
                        <h4>{{ $t('characteristics') }}</h4>
                        <div class="row">
                            <div class="col-4">
                                <div class="form-group">
                                    <input type="text" class="form-control form-control-sm" :placeholder="$t('title')" v-model="characteristic.name">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control form-control-sm" :placeholder="$t('price')" min="0" v-model.number="characteristic.price">
                                </div>
                                <div class="form-group">
                                    <label for="total_quantity">{{ $t('description') }}</label>
                                    <textarea class="form-control" v-model="characteristic.info.description"></textarea>
                                </div>
                                <div class="form-group">
                                    <label>Вибуріть фото</label>
                                    <input type="file" class="form-control-sm" @change="changeInputFile('characteristic', $event)">
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="total_quantity">{{ $t('weight') }}</label>
                                            <input type="text" class="form-control form-control-sm" :placeholder="$t('weight')" min="0" v-model="characteristic.info.weight">
                                        </div>
                                        <div class="form-group">
                                            <label for="total_quantity">{{ $t('volume') }}</label>
                                            <input type="text" class="form-control form-control-sm" :placeholder="$t('volume')" min="0" v-model="characteristic.info.volume">
                                        </div>
                                        <div class="form-group">
                                            <label for="unit_of_weight">{{ $t('unit_of_weight') }}</label>
                                            <select class="form-control form-control-sm" v-model="characteristic.info.unit_of_weight">
                                                <option value=""> -- </option>
                                                <option value="гр">гр</option>
                                                <option value="кг">кг</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <label for="total_quantity">{{ $t('width') }}</label>
                                            <input type="text" class="form-control form-control-sm" :placeholder="$t('width')" min="0" v-model="characteristic.info.width">
                                        </div>
                                        <div class="form-group">
                                            <label for="total_quantity">{{ $t('length') }}</label>
                                            <input type="text" class="form-control form-control-sm" :placeholder="$t('length')" min="0" v-model="characteristic.info.length">
                                        </div>
                                        <div class="form-group">
                                            <label for="total_quantity">{{ $t('height') }}</label>
                                            <input type="text" class="form-control form-control-sm" :placeholder="$t('height')" min="0" v-model="characteristic.info.height">
                                        </div>
                                        <div class="form-group">
                                            <label for="unit">{{ $t('unit') }}</label>
                                            <select class="form-control form-control-sm" v-model="characteristic.info.unit">
                                                <option value=""> -- </option>
                                                <option value="шт">шт</option>
                                                <option value="л">л</option>
                                                <option value="мл">мл</option>
                                                <option value="мп">мп</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-group">
                                    <label for="characteristic_code">{{ $t('characteristic_code') }}</label>
                                    <input type="text" class="form-control form-control-sm" :placeholder="$t('characteristic_code')" v-model="characteristic.info.sku">
                                </div>
                                <div class="form-group">
                                    <label for="type_of_marriage">{{ $t('type_of_marriage') }}</label>
                                    <input type="text" class="form-control form-control-sm" :placeholder="$t('type_of_marriage')" v-model="characteristic.info.type_of_marriage">
                                    <small>Вказувати через кому</small>
                                </div>
                                <div class="form-group">
                                    <label for="type_of_nomenclature">{{ $t('type_of_nomenclature') }}</label>
                                    <select class="form-control form-control-sm" v-model="characteristic.info.type_of_nomenclature">
                                        <option value=""> -- </option>
                                        <option value="stock">Запас</option>
                                        <option value="service">Послуга</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="total_quantity">{{ $t('total_quantity') }}</label>
                                    <input type="text" class="form-control form-control-sm" :placeholder="$t('total_quantity')" min="0" v-model.number="characteristic.total_quantity">
                                </div>
                                <div class="form-group">
                                    <label for="production_quntity">{{ $t('production_quntity') }}</label>
                                    <input type="text" class="form-control form-control-sm" :placeholder="$t('production_quntity')" min="0" v-model.number="characteristic.production_quantity">
                                </div>
                                <button class="btn btn-sm btn-success" @click="addCharacteristic" type="button">{{ $t('add') }}</button>
                            </div>
                        </div>
                        <br>
                        <div v-if="form.characteristics.length > 0">
                            <ul class="list-group">
                                <li v-for="(item, index) in form.characteristics" :key="index" class="list-group-item">
                                    {{ $t('title') }}: <strong>{{ item.title }}</strong>
                                    {{ $t('price') }}: <strong>{{ item.price | currency }}</strong>
                                    {{ $t('total_quantity') }}: <strong>{{ item.total_quantity }}</strong>
                                    {{ $t('production_quantity') }}: <strong>{{ item.production_quantity }}</strong>
                                    <div class="btn-group">
                                        <button class="btn btn-sm btn-outline-success" type="button" @click="openModal(index)">Додати етап</button>
                                        <button class="btn btn-sm btn-outline-danger" type="button" @click="removeCharacteristic(index)"><fa icon="times" /></button>
                                    </div>
                                    <ul v-if="item.stages.length > 0">
                                        <li><strong>{{ $t('stages') }}</strong></li>
                                        <li v-for="(stage, idx) in item.stages" :key="idx">
                                            <button class="btn btn-sm text-danger" type="button" @click="removeStage(index, idx)"><fa icon="times" /></button>
                                            <strong>{{ $t('title') }}</strong>: {{ stage.name }}
                                            <strong>{{ $t('status') }}</strong>:
                                            <span v-if="stage.status === 1" class="badge badge-info">{{ $t('enabled') }}</span>
                                            <span v-else class="badge badge-danger">{{ $t('disabled') }}</span>
                                            <strong>{{ $t('visibility') }}</strong>:
                                            <span v-if="stage.visibility" class="badge badge-success">{{ $t('enabled') }}</span>
                                            <span v-else class="badge badge-danger">{{ $t('disabled') }}</span>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </b-col>
                </b-row>
                <hr>
                <button type="submit" class="btn btn-success" :disabled="form.busy">{{ $t('save') }}</button>
            </form>
            <b-modal id="stage" title="BootstrapVue" hide-footer>
                <div class="form-group">
                    <label for="name">{{ $t('title') }}</label>
                    <input type="text" v-model="stageName" class="form-control">
                </div>
                <div class="form-group">
                    <label for="name">{{ $t('status') }}</label>
                    <select class="form-control" v-model="stageStatus">
                        <option value="1">{{ $t('enabled') }}</option>
                        <option value="0">{{ $t('disabled') }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="name">{{ $t('visibility') }}</label>
                    <select class="form-control" v-model="stageVisibility">
                        <option :value="true">{{ $t('enabled') }}</option>
                        <option :value="false">{{ $t('disabled') }}</option>
                    </select>
                </div>
                <div class="form-group">
                    <button class="btn btn-success" type="button" @click="addStage">{{ $t('add') }}</button>
                </div>
            </b-modal>
    </b-card>
</template>
<script>
import Form from 'vform'
import axios from 'axios'
import { serialize } from 'object-to-formdata'
import Swal from 'sweetalert2'
export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('create') }
    },
    data: () => ({
        form: new Form({
            title: '',
            group_id: '',
            price: 0,
            info: {},
            characteristics: []
        }),
        groups: null,
        characteristic: new Form({
            name: '',
            price: 0,
            total_quantity: '',
            production_quantity: '',
            stages: [],
            info: {
                length: '',
                width: '',
                height: '',
                weight: '',
                volume: '',
                description: '',
                unit_of_weight: 'шт',
                unit: 'гр',
                type_of_marriage: '',
                sku: '',
                type_of_nomenclature: '',
                image: ''
            }
        }),
        stageName: '',
        stageStatus: 1,
        stageVisibility: true,
        stageSelectedIndex: ''
    }),
    mounted () {
        this.getGroups()
    },
    methods: {
        async storeProduct () {
            this.form.busy = true
            const options = {
                indices: true,
                nullsAsUndefineds: false,
                booleansAsIntegers: false,
                allowEmptyArrays: false,
                noFilesWithArrayNotation: false,
                dotsForObjectNotation: false,
            }
            const fd = serialize(this.form.data(), options)
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            await axios.post(apiUrl + 'products', fd,
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(resp => {
                this.form.busy = false
                if (resp.data.status) {
                    this.$router.push({ name: 'products' })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: this.$t('error'),
                        text: resp.data.msg,
                        reverseButtons: true,
                        confirmButtonText: this.$t('ok'),
                        cancelButtonText: this.$t('cancel')
                    })
                }
            }).catch(error => {
                this.form.busy = false
                if (error.response.data && error.response.data.error) {
                    this.form.errors.set(error.response.data.error.errors)
                }
            })
        },
        async getGroups () {
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            await axios.get(apiUrl + 'groups/tree').then(resp => {
                this.groups = resp.data.groups
            })
        },
        changeInputFile (type, e) {
            const file = e.target.files[0]
            this.setImageToForm(file, type)
        },
        setImageToForm (file, type) {
            this[type].info.image = file
        },
        addCharacteristic () {
            if (this.characteristic.name !== '' && this.characteristic.price > 0) {
                this.form.characteristics.push(this.characteristic)
            } else {
                alert('Заповніть назву і ціну характеристики')
            }
        },
        removeCharacteristic (index) {
            this.form.characteristics.splice(index, 1)
        },
        addStage () {
            const characteristic = this.form.characteristics[this.stageSelectedIndex]
            if (typeof characteristic !== 'undefined') {
                characteristic.stages.push({
                    name: this.stageName,
                    photo: null,
                    photo_layout: null,
                    status: Number.parseInt(this.stageStatus),
                    visibility: this.stageVisibility
                })
                // this.$bvModal.hide('stage')
            }
        },
        removeStage (index, stageIndex) {
            if (this.form.characteristics[index]) {
                this.form.characteristics[index].stages.splice(stageIndex, 1)
            }
        },
        openModal (index) {
            this.stageSelectedIndex = index
            this.$bvModal.show('stage')
        }
    }
}
</script>
